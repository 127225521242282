import React from 'react';
import { Form } from 'react-final-form';
import {
  finalTextField,
  finalObjSelector,
  renderFormTitle,
  okCancel,
} from '../../core/FinalCoreForms';
import { useDispatch } from 'react-redux';
import {
  createProduct,
  updateProduct,
} from '../../../redux/actions/store/productsActions';
const _ = require('lodash');

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const ProductForm = (props) => {
  const dispatch = useDispatch();

  function submitProduct(product) {
    if (product && product.id) {
      return dispatch(updateProduct(product)).then(() => {
        //   navigateBack();
      });
    } else {
      return dispatch(createProduct(product)).then(() => {
        //   navigateBack();
      });
    }
  }

  const initialValues = {
    id: props.product && props.product.id ? props.product.id : null,
    name: props.product && props.product.name ? props.product.name : '',
    description:
      props.product && props.product.description
        ? props.product.description
        : '',
    categoryId:
      props.product && props.product.categoryId ? props.product.categoryId : 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (props.product) {
      Object.assign(props.product, values);
      submitProduct(props.product);
    } else {
      let newData = {};
      Object.assign(newData, values);
      submitProduct(newData);
    }
    if (props.onOkClick) {
      props.onOkClick();
    }
  };

  function productCategoriesList() {
    var zeroExists = false;
    if (props.productCategories) {
      let array = _.map(props.productCategories, (idobj) => {
        if (parseInt(idobj.id) === 0) {
          zeroExists = true;
        }
        return {
          value: idobj.id,
          key: idobj.categoryName,
        };
      });
      if (!zeroExists) {
        array.splice(0, 0, { value: null, key: 'None' });
      }
      return array;
    }
    return null;
  }

  return (
    <div>
      {renderFormTitle(props.product, 'Product')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalTextField('name', 'Name')}
            {finalTextField('description', 'Description')}
            {finalObjSelector(
              'categoryId',
              'Category',
              productCategoriesList()
            )}

            {okCancel(props.onOkClick)}
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
};

export default ProductForm;
