import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { PlusCircle } from 'react-feather';
import HelmetTracker from '../../core/HelmetTracker';

import { fetchProducts } from '../../../redux/actions/store/productsActions';
import { fetchProductCategories } from '../../../redux/actions/store/productCategoriesActions';
import { useDispatch, useSelector } from 'react-redux';
import ProductForm from '../forms/ProductForm';

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function ProductsPage() {
  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [addingTest, setAddingTest] = React.useState(false);
  // const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchProductCategories());
  }, [dispatch]);

  const products = useSelector((state) => state.products);
  const product = useSelector((state) =>
    state.products.find((x) => x.id === selected)
  );

  const productCategories = useSelector((state) => state.productCategories);

  const handleClick = (event, id) => {
    if (!addingTest) {
      setSelected(id);
    }
  };

  const handleAddClick = (event) => {
    setSelected(-1);
    setCreating(!creating);
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setAddingTest(false);
    setSelected(-1);
  };

  const isSelected = (id) => selected === id;

  function mapBody(products) {
    if (products && products.length > 0) {
      const tableBody = products.map((product) => {
        const isItemSelected = isSelected(product.id);
        return (
          <TableRow
            key={product.id}
            aria-checked={isItemSelected}
            onClick={(event) => handleClick(event, product.id)}
            selected={isItemSelected}
          >
            <TableCell component="th" scope="row">
              {product.id}
            </TableCell>
            <TableCell>{product.name}</TableCell>
            <TableCell>{product.description}</TableCell>
            <TableCell>{product.category.categoryName}</TableCell>
            <TableCell>{product.sku()}</TableCell>
            <TableCell>{product.fullSku()}</TableCell>
          </TableRow>
        );
      });
      return tableBody;
    }
  }

  function showProductForm() {
    if (selected && selected > 0) {
      if (product && !addingTest) {
        return (
          <ProductForm
            onOkClick={handleFormOkClick}
            product={product}
            productCategories={productCategories}
          />
        );
      }
    } else if (creating) {
      return (
        <ProductForm
          onOkClick={handleFormOkClick}
          productCategories={productCategories}
        />
      );
    }
  }

  const title = 'Products';

  return (
    <>
      <HelmetTracker title="Products" />
      <Card mb={6}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              size="large"
              onClick={(event) => handleAddClick(event)}
            >
              <PlusCircle />
            </IconButton>
          }
          title={title}
        />
        <Paper>
          <TableWrapper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Full SKU</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{mapBody(products)}</TableBody>
            </Table>
          </TableWrapper>
        </Paper>
        {showProductForm()}
      </Card>
    </>
  );
}
