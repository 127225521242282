import React from 'react';
import HorsePhotoCard from './HorsePhotoCard';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { connect } from 'react-redux';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  maxWidth: 850,
  width: '100%',
});

const reorder = (list, startIndex, endIndex, horse, dispatch) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  if (horse) {
    horse.onPhotoMoved(dispatch, result);
  }
};

class HorsePhotosGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoOrder: this.props.horse.properties.photoOrder,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const photoOrder = reorder(
      this.props.horse.properties.photoOrder,
      result.source.index,
      result.destination.index,
      this.props.horse,
      this.props.dispatch
    );

    this.setState({
      photoOrder,
    });
  }

  renderHorsePhotos() {
    if (this.props.horse && this.props.horse.photos) {
      return this.props.horse.photos.map((horsePhoto, index) => {
        return (
          <Draggable
            key={horsePhoto.id}
            draggableId={horsePhoto.id.toString()}
            index={index}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <HorsePhotoCard
                  horsePhoto={horsePhoto}
                  onDelete={this.props.onDelete}
                  onMakeMainPhoto={this.props.onMakeMainPhoto}
                  horse={this.props.horse}
                />
              </div>
            )}
          </Draggable>
        );
      });
    }
    return null;
  }
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.renderHorsePhotos()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default connect()(HorsePhotosGroup);
