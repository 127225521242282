/* AUTO GEN */
/* Horse.js */
import {
  photoToClass,
  photosToClassArray,
  photosToOrderedArray,
} from './Photo';
import { videosToClassArray, videosToOrderedArray } from './Video';
import { documentsToClassArray } from './Document';
import { horseLogItemsToClassArray } from './HorseLogItem';
import { Barn } from './Barn';
import { updateProperties } from '../actions/horseActions';
import _ from 'lodash';

// const order12 = [
//   106, 105, 116, 108, 107, 109, 110, 111, 112, 113, 114, 115, 117, 2000,
// ];
function propertiesTemplate() {
  return { photoOrder: [], videoOrder: [] };
}

function updateProps(dispatch, properties, id) {
  //       var updateObject = { id: horse.id, properties: horse.properties };
}

export class Horse {
  constructor(
    id,
    userId,
    barnId,
    name,
    price,
    owner,
    breed,
    gender,
    color,
    height,
    birthYear,
    description,
    levelCompeting,
    useaNumber,
    usefNumber,
    ushjaNumber,
    specialInformation,
    feed,
    pedigree,
    isSaleHorse,
    sold,
    authorized,
    international,
    photoURL,
    photo,
    raced,
    properties,
    dataStatus,
    photoCount,
    videoCount,
    photos,
    videos,
    horseLogs,
    documents,
    barnUserId,
    barnName,
    barnOwnerName
  ) {
    this.id = id;
    this.userId = userId;
    this.barnId = barnId;
    this.name = name;
    this.price = price;
    this.owner = owner;
    this.breed = breed;
    this.gender = gender;
    this.color = color;
    this.height = height;
    this.birthYear = birthYear;
    this.description = description;
    this.levelCompeting = levelCompeting;
    this.useaNumber = useaNumber;
    this.usefNumber = usefNumber;
    this.ushjaNumber = ushjaNumber;
    this.specialInformation = specialInformation;
    this.feed = feed;
    this.pedigree = pedigree;
    this.isSaleHorse = isSaleHorse;
    this.sold = sold;
    this.authorized = authorized;
    this.international = international;
    this.photoURL = photoURL;
    this.raced = raced;
    this.dataStatus = dataStatus;
    this.photoCount = photoCount;
    this.videoCount = videoCount;
    if (photo) {
      this.photo = photoToClass(photo);
    }
    if (photos) {
      // if (id === 12) {
      //   this.photos = photosToOrderedArray(photos, order12);
      // } else {
      this.photos = photosToClassArray(photos);
      // }
    }
    if (videos) {
      this.videos = videosToClassArray(videos);
    }
    if (horseLogs) {
      this.horseLogs = horseLogItemsToClassArray(horseLogs);
    }
    if (documents) {
      this.documents = documentsToClassArray(documents);
    }
    this.barn = new Barn(barnId, barnUserId, barnName, 0, barnOwnerName);

    if (properties) {
      this.properties = properties;
      if (photos) {
        this.photos = photosToOrderedArray(photos, this.properties.photoOrder);
      }
      // if (videos) {
      //   if (id === 193) {
      //     console.log(videos);
      //   }
      //   this.videos = videosToOrderedArray(videos, this.properties.videoOrder);
      // }
    } else {
      this.properties = propertiesTemplate();
      this.fillPhotoOrder();
      this.fillVideoOrder();
      // console.log('missing');
      // console.log(this.id);
    }
  }

  fillPhotoOrder() {
    if (this.photos) {
      // console.log('this.photos');
      // console.log(this.id);
      // console.log(this.photos);
      if (this.properties && this.properties.photoOrder) {
        this.photos.forEach((photo) => {
          this.properties.photoOrder.push(photo.id);
        });
      }
      // console.log(this.properties.photoOrder);
    }
  }

  fillVideoOrder() {
    if (this.videos) {
      if (this.properties && this.properties.videoOrder) {
        this.videos.forEach((video) => {
          this.properties.videoOrder.push(video.id);
        });
      }
    }
  }

  pedigreeEmptyOrPartial() {
    return JSON.stringify(this.pedigree).toLowerCase().includes('unknown');
  }

  getMainPhotoURL() {
    if (this.photo) {
      return this.photo.amazons3URL;
    }
    return this.photoURL;
  }

  getMainPhotoURLThumb() {
    if (this.photo) {
      return this.photo.amazons3URLThumb;
    }
    return this.photoURL;
  }

  getMainPhotoURLHalf() {
    if (this.photo) {
      return this.photo.amazons3URLHalf;
    } else if (this.photoURL) {
      this.photoURL.replace('thumb', 'half');
    }
  }

  hasPhoto() {
    return (this.photoURL && this.photoURL.length > 1) || this.photo;
  }

  movePhotoUp(dispatch, horsePhotoId) {
    if (this.properties && this.properties.photoOrder) {
      var index = _.indexOf(this.properties.photoOrder, horsePhotoId);
      if (index > 0) {
        var tmp = this.properties.photoOrder[index - 1];
        this.properties.photoOrder[index - 1] =
          this.properties.photoOrder[index];
        this.properties.photoOrder[index] = tmp;
        this.photos = photosToOrderedArray(
          this.photos,
          this.properties.photoOrder
        );
        var values = { id: this.id, properties: this.properties };
        dispatch(updateProperties(values));
      }
    }
  }

  onPhotoMoved(dispatch, photoOrder) {
    if (this.properties && this.properties.photoOrder) {
      console.log(this.properties.photoOrder);
      console.log(photoOrder);
      this.properties.photoOrder = photoOrder;
      this.photos = photosToOrderedArray(
        this.photos,
        this.properties.photoOrder
      );

      var values = { id: this.id, properties: this.properties };
      dispatch(updateProperties(values));
    }
  }

  movePhotoDown(dispatch, horsePhotoId) {
    if (this.properties && this.properties.photoOrder) {
      var index = _.indexOf(this.properties.photoOrder, horsePhotoId);
      if (index > -1 && index < this.properties.photoOrder.length - 1) {
        var tmp = this.properties.photoOrder[index + 1];
        this.properties.photoOrder[index + 1] =
          this.properties.photoOrder[index];
        this.properties.photoOrder[index] = tmp;
        this.photos = photosToOrderedArray(
          this.photos,
          this.properties.photoOrder
        );
        var values = { id: this.id, properties: this.properties };
        dispatch(updateProperties(values));
      }
    }
  }

  removePhoto(photoId) {
    if (this.properties && this.properties.photoOrder) {
      _.indexOf(this.properties.photoOrder, photoId);
      _.remove(this.properties.photoOrder, (n) => n === photoId);
    }
  }

  deletePhoto(dispatch, photoId) {
    this.removePhoto(photoId);
    var values = { id: this.id, properties: this.properties };
    dispatch(updateProperties(values));
  }
}

export function horseToClass(horse) {
  if (horse) {
    return new Horse(
      horse.id,
      horse.userId,
      horse.barnId,
      horse.name,
      horse.price,
      horse.owner,
      horse.breed,
      horse.gender,
      horse.color,
      horse.height,
      horse.birthYear,
      horse.description,
      horse.levelCompeting,
      horse.useaNumber,
      horse.usefNumber,
      horse.ushjaNumber,
      horse.specialInformation,
      horse.feed,
      horse.pedigree,
      horse.isSaleHorse,
      horse.sold,
      horse.authorized,
      horse.international,
      horse.photoURL,
      horse.photo,
      horse.raced,
      horse.properties,
      horse.dataStatus,
      horse.photoCount,
      horse.videoCount,
      horse.photos,
      horse.videos,
      horse.horseLogs,
      horse.documents,
      horse.barnUserId,
      horse.barnName,
      horse.barnOwnerName
    );
  } else {
    return null;
  }
}

export function horsesToClassArray(horses) {
  if (horses) {
    const horsesArray = horses.map((item) => {
      return horseToClass(item);
    });
    return horsesArray;
  }
}
