/* AUTO GEN */
/* Products.js */
import { ProductCategory } from './ProductCategory';

export class Product {
  constructor(
    id,
    categoryId,
    name,
    description,
    productImage,
    dataStatus,
    category
  ) {
    this.id = id;
    this.categoryId = categoryId;
    this.name = name;
    this.description = description;
    this.productImage = productImage;
    this.dataStatus = dataStatus;
    this.category = category;
  }

  sku() {
    return this.id.toString().padStart(6, '0');
  }

  fullSku() {
    return this.category.sku() + '-' + this.sku();
  }
}

export function productToClass(product) {
  if (product) {
    var category = new ProductCategory(
      product.categoryId,
      product.parentCategoryId,
      product.categoryName,
      product.parentCategoryName,
      product.categoryDataStatus
    );
    return new Product(
      product.id,
      product.categoryId,
      product.name,
      product.description,
      product.productImage,
      product.dataStatus,
      category
    );
  } else {
    return null;
  }
}

export function productsToClassArray(products) {
  if (products) {
    const productsArray = products.map((item) => {
      return productToClass(item);
    });
    return productsArray;
  }
}
